.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 400px;
  min-height: 100vh;
}
body {
  overflow-y: scroll;
}
select {
  font-family: "Open Sans";
  margin-right: 0;
}

input[type="date"],
input[type="datetime-local"] {
  font-family: "Open Sans";
  border-radius: 5px;
  border: 1px black solid;
  padding: 0;
  padding-left: 3px;
  height: 23px;
}

option {
  padding: 0;
}
.App-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.navCategoryExpanded {
  width: 150px;
  background: rgb(240, 240, 240, 0.9);
  margin-left: auto;
}
.submenu {
  background: rgb(240, 240, 240, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.submenuActive,
.submenuUnactive {
  width: 150px;
  height: 22px;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  font-size: 18px;
  cursor: pointer;
  outline: inherit;
  color: #f9323f;
  font-size: 16px;
  transition: font-size 200ms;
}
.submenuUnactive:hover,
.submenuActive:hover {
  font-size: 18px;
  transition: font-size 200ms;
}
.submenuActive {
  background: rgb(240, 240, 240, 0);
  font-weight: 900;
}
.submenuUnactive {
  color: #35647e;
}
.landingLogo {
  height: 30vh;
  width: auto;
  animation: fadeIn 5s, rotate 3s infinite;
  opacity: 1;
  z-index: -1;
  margin: auto;
}
.still {
  animation: none;
}
* :not(.submenu, .landingLogo, ) {
  animation: fadeIn 1s;
}
.navGclass {
  height: 80px;
}
h1,
h2 {
  margin: 0;
  color: #35647e;
}
.hwAddNew,
.hwMoveBulk,
.scMoveBulk {
  width: 90%;
}
.css-v44rok-MuiTableCell-root {
  padding: 0;
}
.globalSelectionGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: rgba(53, 100, 126, 0.1);
  box-shadow: -2px -2px 5px 2px rgba(0, 0, 0, 0.2);
  border: 1px rgba(0, 0, 0, 1) solid;
  border-radius: 5px;
  position: relative;
  width: 100%;
  max-width: 430px;
  margin: 20px auto;
  padding-bottom: 4px;
}

.globalSelection {
  height: 100%;
  margin-top: 5px;
  width: 100%;
  min-width: 350px;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  font-family: "Open Sans";
  margin: 20px auto;
  align-items: stretch;
  transition: width 20s, height 40s;
}
.wide {
  flex-direction: unset;
}
.dasLine {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 4px;
}
.dasDesc {
  text-align: right;
  text-shadow: -2px -0 5px rgba(0, 0, 0, 0.2);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 26px;
}
.dasValue {
  width: 70%;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 5px;
  animation: fadeIn 1s;
  margin: 0 5px 0 10px;
  height: 26px;
}
.smallTableWrapper {
  box-shadow: -2px -2px 5px 2px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 900px;
  margin: 10px auto;
  overflow-x: auto;
}
.notTop {
  width: 100%;
}
.topBar {
  height: 3vh;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ytable {
  min-width: 700px;
}
.link {
  width: 30px;
  margin-right: 280px;
  padding: 2px;
}
.link:hover {
  cursor: pointer;
  background: #f9323f;
  color: white;
  border-radius: 7px;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes blur {
  0% {
    color: rgb(255, 0, 0);
  }
  10% {
    color: rgb(255, 0, 0);
  }
  20% {
    color: rgb(250, 0, 0);
  }
  30% {
    color: rgb(225, 0, 0);
  }
  40% {
    color: rgb(200, 0, 0);
  }
  50% {
    color: rgb(175, 0, 0);
  }
  60% {
    color: rgb(150, 0, 0);
  }
  70% {
    color: rgb(125, 0, 0);
  }
  80% {
    color: rgb(100, 0, 0);
  }
  90% {
    color: rgb(50, 0, 0);
  }
  100% {
    color: rgb(0, 0, 0);
  }
}
.xlsxIcoTall {
  margin: 15px auto;
  width: 20px;
}
.xlsxIco {
  margin: 2px auto;
  width: 20px;
}
.bigTableWrapper {
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  animation: fadeIn 1s;
  flex-direction: column;
  margin-bottom: 10px;
}
.bigTable {
  overflow-x: scroll;
  overflow-y: scroll;

  box-shadow: -5px -5px 10px 5px rgba(0, 0, 0, 0.2);
  border: 1px solid black;
  display: flex !important;
  justify-content: flex-start;
  animation: fadeIn 1s;
  flex-direction: column;
}
.midTableWrapper {
  width: 100%;
  overflow: auto;
  box-shadow: -2px -2px 5px 2px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  animation: fadeIn 1s;
}
.smallTable,
.smallTable2 {
  border: 1px black solid;
  margin-left: auto;
  margin-right: auto;
}
.smallTable {
  max-width: 900px;
}
.midTable {
  border: 1px black solid;
}
.oko5 {
  width: 100%;
  height: 3px;
}
.oko5 > * {
  border-radius: 0 !important;
  height: 20px;
  width: 100%;
  padding: 0;
  margin: 3px;
  border: none;
}
.Services,
.logsTables,
.DataUsage,
.Assets,
.serviceUsage,
.Orders {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 90%;
  justify-content: flex-start;
}
.logsTableSelector {
  height: 20px;
  width: 100px;
  margin: 15px 0;
  align-self: center;
}

.HardwareAddNew,
.HardwareMoveBulk,
.SimcardAddNew,
.SimcardMoveBulk,
.HardwareDetailsWrapper,
.SimcardDetailsWrapper,
.HardwareDetailsWrapper {
  margin-top: 30px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  transition: 2s;
  animation: fadeIn 1s;
  align-self: center;
}
.serviceDataUsageTableWrapper25 {
  height: 25%;
  min-height: 300px;
  margin: 1vh auto 10px auto;
  width: 100%;
}
.serviceDataUsageTableWrapper45 {
  height: 40%;
  margin: 1vh auto auto auto;
  width: 100%;
}
.tableButtons {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #35647e;
  color: white;
  width: 100%;
}
.tableButtonsG1 {
  margin: 5px;
  height: 20px;
}

.tableButtonsG1 > * {
  height: 20px;
  min-width: 20px;
  padding: 1px 3px;
}
.tableButtonsG2 {
  display: flex;
  justify-content: center;
  margin: 5px;
  height: 20px;
  width: 230px;
}
.pageTitle {
  font-size: 25px;
  color: #35647e;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: 30px;
  width: 90%;
  margin-top: 70px;
  margin-left: auto;
  margin-right: auto;
}
.dirtySyncInfo {
  margin: 15px 0;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.maintenanceSelectors {
  display: flex;
  align-self: center;
}
.maintenanceSelectors > * {
  width: 200px;
}
.simpleTable > tbody > tr > *,
.simpleTable > thead > tr > * {
  border: 1px black solid;
  margin: 0;
  background-color: rgb(220, 220, 220, 0.3);
  text-align: left;
}
.simpleTable > thead > tr > * {
  background-color: rgb(220, 220, 220, 0.9);
  color: #35647e;
  font-size: 1.2rem;
}
td > input,
td > select {
  display: inline-block;
  max-width: 90%;
  overflow: hidden;
}
.simpleTable {
  table-layout: auto;
  margin: 0 auto;
  border-spacing: 0;
  max-width: 90%;
}
.psqlEditSelector {
  margin: 20px auto;
  width: 200px;
}
.mapInfoWindow {
  max-width: 250px;
  max-height: 300px;
}
.mapInfoLine {
  max-width: 250px;
  text-align: left;
  margin: 4px 0;
  display: flex;
}
.mapInfoKeys {
  display: inline-block;
  width: 80px;
}
.mapInfoValues {
  display: inline-block;
  width: 66%;
}
.multiSelectFilter {
  display: flex;
}
.MuiPaper-root {
  width: unset !important;
  transition: unset !important;
  border: 1px solid black;
  border-radius: 0 !important;
}
.MuiButtonBase-root {
  font-family: "Open Sans" !important;
  padding: 0 3px !important;
  font-size: 15px !important;
}
.css-tlelie-MuiListItemText-root {
  height: 100%;
  /* margin-top: 0 !important; */
  /* margin-bottom: 0 !important; */
  /* margin-right: 5px !important; */
}
.css-i4bv87-MuiSvgIcon-root {
  height: 16px !important;
  width: 16px !important;
  padding: 1px !important;
}

.css-10hburv-MuiTypography-root {
  /* font-size: 15px !important; */
}
.MuiInputBase-root {
  font-family: "Open Sans" !important;
  padding: 0 3px !important;
  background-color: white !important;
}
.MuiSelect-select {
  padding: 0 !important;
}
.infoLink:hover {
  cursor: pointer;
  background-color: #f9323f;
  color: white;
}
.contactsForm {
  margin: 10px auto;
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cuaChecks {
  margin-top: 2px;
  display: flex;
  justify-content: center;
  width: 100%;
}
.cuaChecks > * {
  width: 33%;
}
.orgFilters {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  align-items: center;
  justify-content: center;
}
.orgFilter {
  margin: 5px;
  width: 160px;
}
.mailChecks {
  margin: 5px auto;
  width: 80%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.mailCheck {
  height: 25px;
  margin: 5px;
  width: 210px;
  display: flex;
  border: 0.2px solid black;
  border-width: 1px;
  border-color: rgb(200, 200, 200);
  border-radius: 4px;
  justify-content: center;
  align-items: center;
}
.mailCheckDesc {
  margin: 3px;
  width: 85%;
}
.mailCheckCheck {
  margin: 0 3px !important;
  height: 15px !important;
  width: 10%;
}
.newDelDate > .MuiInputBase-sizeSmall > input[type="text"],
.newDelText > .MuiInputBase-sizeSmall > input[type="text"] {
  padding: 8.5px 0px 8.5px 15px;
  height: unset;
  border: unset;
}
.newDelDate > .MuiInputBase-sizeSmall > .MuiInputAdornment-sizeSmall > button {
  margin-right: 0;
}
.oko5 > .MuiInputBase-root > .MuiSelect-select {
  padding: 5px;
}
.koffersMainWrapper {
  padding: 5px;
  display: flex;
  height: 80vh;
  width: 90%;
  margin: 0 auto;
}

.koffersMainWrapper > .bigTableWrapper {
  width: 60%;
}
.koffersMainWrapper > .bigTableWrapper > * {
  margin: 0 8px;
  height: 100%;
}
#textFieldAddDevices,
#dropSelectAddDevices,
#datePickerAddDevices,
#dateTimePickerAddDevices {
  height: 26px;
  padding: 0;
}
.wide {
  height: 100%;
  align-items: stretch;
}
.newInvoicesSteps {
  margin: 2px;
  padding: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background: gray;
  z-index: 10;
  background: rgb(220, 220, 220, 0);
  box-shadow: -2px -2px 5px 2px rgba(0, 0, 0, 0.2);
  border: 1px rgba(0, 0, 0, 1) solid;
  border-radius: 5px;
}
.newInvoicesStepsSub {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.disabledInvoicesStep {
  box-shadow: unset;
  border: unset;
  margin: 3px;
}
.disabledInvElement {
  opacity: 0.4;
}
.invoicePreselectPanel {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background: rgba(53, 100, 126, 0.1);
  box-shadow: -2px -2px 5px 2px rgba(0, 0, 0, 0.2);
  border: 1px rgba(0, 0, 0, 1) solid;
  border-radius: 5px;
  position: relative;
  width: 100%;
  min-width: 860px;
  margin: 30px auto;
  padding-bottom: 4px;
  align-items: center;
}
.subInvoicePanel {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background: rgba(53, 100, 126, 0.1);
  box-shadow: -2px -2px 5px 2px rgba(0, 0, 0, 0.2);
  border: 1px rgba(0, 0, 0, 1) solid;
  border-radius: 5px;
  position: relative;
  width: 80%;
  min-width: 900px;
  margin: 15px;
  padding-bottom: 4px;
  align-items: center;
}
.invoiceGeneralHeader {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  min-width: 860px;
}
.invoiceGeneralPanel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: rgba(53, 100, 126, 0.1);
  box-shadow: -2px -2px 5px 2px rgba(0, 0, 0, 0.2);
  border: 1px rgba(0, 0, 0, 1) solid;
  border-radius: 5px;
  position: relative;
  width: 100%;
  min-width: 950px;
  margin: 30px auto;
  align-items: center;
}
.invoicePreselectPanel > * {
  margin: 5px;
}
.invoicePanelDisabled {
  opacity: 0.4;
}
.invoicePreselectDescA {
  margin: 5px;
  min-width: 350px;
  width: 30%;
  text-align: right;
}
.invoicePreselectDescB {
  display: flex;
  margin: 5px;
  min-width: 350px;
  text-align: right;
}
.invoicePreselectDesc1stLine {
  margin: auto;
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.invoicePreselectLines {
  min-width: 450px;
  width: 100%;
  margin: 10px;
}
.invoicingBody {
  width: 100%;
}
.invoicePreselectDesc1stLine > .dasLine {
  margin-top: 0px;
  width: unset;
  max-width: 85%;
}
.invoicePreselectCheckbox {
  min-width: 20px;
  width: 20px;
}
.invoicePreselectCheckbox > * {
  min-width: 20px;
  width: 20px;
}
.invNavMain {
  background: gray;
  z-index: 3;
  background: rgb(220, 220, 220, 0.9);
  box-shadow: -2px -2px 5px 2px rgba(0, 0, 0, 0.2);
  border: 1px rgba(0, 0, 0, 1) solid;
  border-radius: 5px;
  position: fixed;
  top: 120px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.invNavSub {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.invBodyMain {
  top: 250px;
  position: relative;
  width: 100%;
}
.invNavMainButton {
  margin: 5px;
  background: #4ab217;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  height: 30px;
  width: 80px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: -2px 0 5px rgb(0 0 0 / 80%);
  transition: font-size 200ms;
}
.invNavIncludeButton {
  width: 100px;
}
.inactiveButton {
  box-shadow: none;
  background: rgba(0, 0, 0, 0.2);
}
.includeCounter {
  width: 100px;
}
.MuiTableRow-root {
  animation: unset;
}
.invoiceToProcess {
  width: 80%;
  display: flex;
  flex-direction: row;
  text-align: left;
  align-items: center;
  margin-top: 10px;
}
.invoiceToProcessDesc {
  width: 40%;
}
.invoiceToProcessDescLine {
  margin: 0;
}
.invoiceGeneralHeader > * {
  margin: 5px;
}
.subInvoicePanel > .invoicePreselectDescA > p {
  margin: 1px;
  text-align: left;
}
.subInvoicePanel > .invoicePreselectDescB > p {
  margin: 1px;
  text-align: left;
}
.invoicingFlag {
  margin: -3px 5px 0 0;
}
.invoicingCopied {
  animation: blur 1s;
}
.elipsisRow {
  max-width: 200;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 1px !important;
  text-align: left !important;
}
.campInit {
  display: flex;
  flex-direction: column;
  font-family: "Open Sans";
  width: 100%;
  margin: auto;
  max-width: 800px;
}
/* .campInit > * {
  display: flex;
  margin: 5px;
}
.campInit > * > * {
  width: 120px;
  margin: 5px;
}
.campInit > * > * > * > * {
  width: 200px;
  margin: 5px;
} */
.campConfButton,
.campConfButtonInactive {
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  height: 50px;
  width: 200px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: -2px 0 5px rgb(0 0 0 / 80%);
  margin-bottom: 10px;
}
.campConfButton {
  background: #4ab217;
  transition: font-size 200ms;
}
.campConfButtonInactive {
  box-shadow: none;
  background: rgba(0, 0, 0, 0.2);
  cursor: default;
}

.campConfButtonInactive:hover {
  color: white;
  cursor: not-allowed;
  box-shadow: none;
}
.offerHeader {
  padding: 1px;
  margin: 0;
  box-sizing: content-box;
  overflow-wrap: break-word;
  display: block;
  unicode-bidi: isolate;
  font-family: "Open Sans";
  font-size: 18px;
  line-height: 1.75em;
  color: hsla(201, 41%, 35%, 1);
  padding-bottom: 0;
  margin-bottom: 0;
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}
.offerHeader > * {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.offerHeader > ul {
  padding-left: 29px;
}
.offerTableLine {
  display: flex;
  width: 100%;
}
.offerTableLine > * {
  min-width: 170px;
}
.offerTableLine:first-child {
  min-width: 175px;
  max-width: 25%;
}
.offerComleted > .offerTableLine:first-child {
  min-width: 175px;
  max-width: 100%;
}
.offerHeader > div > a {
  color: #f9323f;
  text-decoration: underline;
}
.offerHeader > div > form {
  justify-content: left;
}
.offerHeader > div > form > * {
  min-width: 11%;
  text-align: left;
}
.offerHeader > div > form > div > div > div > div > span {
  margin-left: 5px;
  font-size: 14px;
}
.offerHeader > div > form > .dasDesc {
  margin-top: -3px;
}
.noOfferResponse {
  text-align: center;
}
.campCheck {
  display: flex;
  flex-direction: row;
}
.campCheck > form {
  margin-right: 6px;
  margin-left: -5px;
}
.campCheck > form > div {
  margin-right: 0px;
}
.spacer {
  height: 10px;
}
.offerHeader > div > div > form > .dasDesc,
.campInit > div > form > .dasDesc {
  text-shadow: none;
  margin-right: 0;
  max-width: 130px;
  /* min-width: 70px; */
}

.contactInputs,
.campInit {
  /* padding: 1px; */
  /* margin: 0; */
  box-sizing: content-box;
  overflow-wrap: break-word;
  /* display: block; */
  unicode-bidi: isolate;
  font-family: "Open Sans";
  font-size: 18px;
  line-height: 1.75em;
  color: hsla(201, 41%, 35%, 1);
  /* padding-bottom: 0; */
  /* margin-bottom: 0; */
  /* width: 100%; */
  /* max-width: 800px; */
  /* margin-left: auto; */
  /* margin-right: auto; */
  text-align: left;
  display: flex;
  flex-direction: column;
  min-width: 420px;
  width: 95%;
  max-width: 620px;

  padding-right: 50px;
  height: 160px;
  /* align-items: center; */
  margin: auto;
}
.campInit {
  min-width: unset;
  max-width: 400px;
}
.contactInputs > div > form > .MuiFormControl-root,
.campInit > div > form > .MuiFormControl-root {
  max-width: 90%;
  min-width: 150px;
  margin-left: -5px;
  margin-bottom: -2px;
  margin-top: 2px;
}
.contactInputs > div > form > .MuiFormControl-root > div,
.campInit > div > form > .MuiFormControl-root > div {
  padding: 0 !important;
  width: 95%;
  min-width: 150px;
  margin: 0 10px 0 5px;
}
.contactInputs > div > form > .MuiFormControl-root > div > div > div > span,
.campInit > form > .MuiFormControl-root > div > div > div > span {
  margin: -1px 0 0 8px;
  /* margin-left: ; */
}
.contactInputs > div > form,
.campInit > form {
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: flex-end;
}
.campRemarks {
  margin-top: 5px;
  display: flex;
  height: 90px;
  align-items: flex-start;
}
.campRemarks > form {
  display: flex;
  height: 80px;
  align-items: flex-start;
}
.initHeader {
  max-width: 350px;
}
.initInputs {
  min-width: 300px;
  height: 100px;
}
.initInputs > form > div {
  text-shadow: none;
}
.initInputs > div > form > .MuiFormControl-root {
  justify-content: flex-end;
  align-items: flex-end;
  margin-left: 0;
}
.initInputs > div > form > .MuiFormControl-root > div {
  min-width: 180px;
  max-width: 55%;
}
.initHeader > div > form {
  justify-content: flex-end;
}
.initInputs > button {
  align-self: flex-end;
  /* margin-top: 10px; */
  width: 172px;
  height: 30px;
  margin: 5px 5px 10px 0;
  padding: 0;
}
.initHeader > div > form > * {
  min-width: 11%;
  text-align: left;
  width: auto;
}
.errorRes {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Open Sans";
  font-size: 18px;
  color: #35647e;
}
.secCodeInput > .MuiFormControl-root > div > #filled-adornment-password {
  padding-bottom: 0;
  padding-left: 3px;
  padding-right: 3px;
  padding-top: 0;
  /* width: 151px; */
  margin-left: 0 !important;
  height: 26px;
}
.secCodeInput > .MuiFormControl-root {
  width: 171px;
}
.secCodeInput > .MuiFormControl-root > div > div button {
  /* margin-left: -5px; */
  margin-right: 0px;
  /* background-color: red; */
}
.carAnim {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.carAnim > img {
  /* margin-top: -80px; */
  margin-bottom: -80px;
  width: 350px;
}
.campThanksHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  text-align: center;
}
.offerHeader > ul {
  width: 90%;
}
.errorDesc {
  color: #f9323f;
}
.teminalMain {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}
