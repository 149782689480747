.HardwareDetailsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 0.9;
  justify-content: flex-start;
}
.harDetails {
  width: 90%;
}
.details {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  flex: 1 1 0;
  text-shadow: -2px -0 5px rgba(0, 0, 0, 0.2);
}
.tab {
  flex: 1 1 0;
  min-width: 350px;
  max-width: 600px;
  margin: 30px;
}
.hwDetails1,
.hwDetails2,
.hwDetails3,
.history {
  background: rgba(53, 100, 126, 0.1);
  border: 1px rgba(0, 0, 0, 0.15) solid;
  border-radius: 5px;
  box-shadow: -2px -0 5px rgba(0, 0, 0, 0.2);
}
.hwDetails3 {
  max-height: 300px;
}
.history,
.MuiTableHead-root {
  border-radius: 5px;
}
.hwDetails1Line {
  display: flex;
}
.key,
.value {
  height: 25px;
  margin: 5px;
  line-height: 25px;
}
.key {
  width: 30%;
  text-align: right;
}
.value {
  width: 60%;
  text-align: left;
}
.mainButtonsAAD {
  display: flex;
  width: 300px;
  height: 50px;
  align-items: flex-end;
  justify-content: space-evenly;
}
.mainButtonsAAD > button {
  background: #4ab217;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  width: 80px;
  height: 30px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: -2px -0 5px rgba(0, 0, 0, 0.8);
}
.mainButtonsAAD > button:hover {
  color: black;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.8);
}
.mainButtonsAAD > .disabled {
  box-shadow: none;
  background: white;
  cursor: default;
}
.mainButtonsAAD > .cancel {
  background: #f9323f;
}
.info {
  background: none;
  border: none;
  color: #4ab217;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  width: 80px;
  height: 30px;
  cursor: default;
  border-radius: 5px;
  box-shadow: none;
}
.filterInput {
  width: 80%;
}
.deviceDetailsHistory {
  width: 100%;
  overflow: auto;
  box-shadow: -5px -5px 10px 5px rgba(0, 0, 0, 0.2);
  border: 1px solid black;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  animation: fadeIn 1s;
}
select {
  height: 25px;
  width: 81.5%;
}
input {
  width: 80%;
  height: 20px;
}
.smallDateInput {
  width: 40px;
}
.ll {
  margin-top: 20vh;
}
.value > input,
select {
  text-align: left;
  box-shadow: -2px -0 10px rgba(0, 0, 0, 0.2);
  background: white;
  border: 1px black solid;
  border-radius: 5px;
  animation: fadeIn 1s;
}
