.poolDataChart {
  width: 90%;
  overflow: hidden;
  max-height: 400px;
  min-height: 250px;
}
.usageAlertsSelector > input::-webkit-outer-spin-button,
.usageAlertsSelector > input::-webkit-inner-spin-button {
  -webkit-appearance: auto;
  margin: 0;
}
.usageAlertsSelector > input[type="number"] {
  -moz-appearance: auto;
}
.usageAlertsSelector > input {
  width: 40px;
}
/* .DataUsage {
  display: flex;
} */
.usageAlerts,
.poolDataUsage,
.poolDataChart {
  margin-bottom: 30px;
  width: 90%;
  box-shadow: -5px -5px 10px 5px rgba(0, 0, 0, 0.2);
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0.95 0.9 auto;
  animation: fadeIn 1s;
  border-radius: 10px 10px 10px 10px;
}
.poolDataChart {
  height: 35vh;
}
.usageAlerts {
  flex: 0.1;
}
.poolDataUsage {
  max-height: 500px;
  overflow-y: scroll;
  height: 300px;
}
.usageAlertsSelectors {
  max-width: 800px;
  min-width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  flex-wrap: wrap;
  flex-direction: row;
}
.usageAlertsSelector {
  min-width: 150px;
  margin: 0 10px 0 10px;
}
