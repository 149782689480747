.inventoryMenu {
  margin: 20px;
  border: 1px black solid;
  width: 60%;
  display: flex;
  justify-content: space-evenly;
}
.inventoryType {
  margin: 10px;
}
.searchField {
  margin: 10px;
}
.menuButtons {
  margin: 20px;
  display: flex;
  width: 50px;
}
.loading {
  cursor: wait;
}
.servicesPrecheck {
  display: flex;
  z-index: 1;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
