.Navbar {
  align-self: center;
  display: flex;
  align-items: flex-start;
  background: rgb(220, 220, 220, 0.9);
  width: 90%;
  flex-grow: 0.15;
  border-radius: 10px;
  padding: 5px 0;
  margin: 5px auto;
  box-shadow: -5px -5px 10px 5px rgb(0 0 0 / 20%);
  border: 1px solid black;
  z-index: 10;
  height: 70%;
  position: fixed;
  top: 2px;
  left: 50%;
  transform: translate(-50%, 0%);
}
.navLogo {
  height: 40px;
  width: auto;
  overflow: hidden;
  margin-left: 10px;
  padding-right: 25px;
  align-self: center;
  /* dev : */
  /* filter: invert(1); */
}
.navbarLink,
.navbarLinkActive {
  padding-bottom: 3px;
  height: 20px;
  text-align: center;
  min-width: 140px;
  font-size: 18px;
  border-bottom: 3px rgb(220, 220, 220, 0) solid;
}
.navbarLink {
  color: #35647e;
  transition: font-size 200ms;
}
.navbarLinkActive {
  color: #f9323f;
  font-size: 18px;
  transition: font-size 200ms;
}
.horizontal {
  margin-top: 15px;
}
.navLinks {
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
  justify-self: flex-end;
}
a {
  text-decoration: none;
}

.navbarLink:hover,
.navbarLinkActive:hover,
.navLinks > a:hover {
  cursor: pointer;
  outline-style: solid;
  outline: #35647e;
  font-size: 20px;
  transition: font-size 100ms;
}
.navCategory {
  width: 100px;
  z-index: 6;
}
.submenu {
  height: 0;
  overflow: hidden;
  z-index: 6;
}
.Navbar .navLinks {
  display: flex;
}
.hamburger {
  z-index: 6;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes grow {
  0% {
    font-size: 18px;
  }
  100% {
    font-size: 22px;
  }
}
