.inventoryMenu {
  margin: 20px;
  border: 1px black solid;
  width: 60%;
  display: flex;
  justify-content: space-evenly;
}
.inventoryType {
  margin: 10px;
}
.searchField {
  margin: 10px;
}
.menuButtons {
  margin: 20px;
  display: flex;
  width: 50px;
}
.MuiTableRow-head:first-child td:first-child {
  border-top-left-radius: 10px;
}
.MuiTableRow-head:first-child td:last-child {
  border-top-right-radius: 10px;
}
.loading {
  cursor: wait;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
