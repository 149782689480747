.antennaBandsTable {
  width: 80%;
}
.table {
  height: calc(100vh - 65px);
  position: relative;
  font-family: "SFProDisplay", sans-serif;
  font-size: 13px;
  box-shadow: -2px -0 5px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  width: 600px;
  border: 1px solid black;
  border-radius: 5px;
}
.heading {
  width: 100% !important;
}
.oko,
.oko6 {
  display: flex;
  justify-content: space-evenly;
}
.oko2 {
  width: 10%;
}
.normalTableRow:nth-of-type(odd) {
  background: rgba(0, 0, 0, 0.1);
}
.MuiTableRow-root {
  animation: fadeIn 1s;
}
.tableLink:hover {
  cursor: pointer;
  background: #f9323f;
  color: white;
  border-radius: 7px;
}

input {
  width: 80%;
  z-index: 1;
}
.css-v44rok-MuiTableCell-root {
  text-align: left !important;
}
.table {
  display: inline-block;
  border-spacing: 0;
  border: 1px solid black;
}
th {
  font-family: Quenda, serif !important;
  -webkit-font-smoothing: antialiased !important;
}
td {
  font-family: "Open Sans" !important;
}
.oko4 {
  margin-left: 4px;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
