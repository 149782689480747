/* @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;800&display=swap"); */

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

@font-face {
  font-family: "Quenda";
  src: local("Quenda"), url("./quenda/Quenda-Medium.otf") format("opentype");
  font-weight: bold;
}
body {
  margin: 0;
  font-size: 15px;
  font-family: Quenda, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
input[type="datetime-local"] {
  font-family: "Open Sans", Helvetica, Arial, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
input[type="checkbox"] {
  margin: 0;
  accent-color: #35647e;
  color: white;
  border: 1px black solid;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
