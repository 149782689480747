.scanLineTag {
  display: flex;
  align-items: center;
  justify-content: center;
}
.scanLineTag > div {
  display: flex;
  flex-direction: column;
  margin: 10px;
}
.notTopAAD {
  width: 100%;
}
.serialInput,
.tagInput {
  margin: auto;
  box-shadow: -2px -2px 5px 2px rgba(0, 0, 0, 0.2);
  background: white;
  border: 1px black solid;
  border-radius: 5px;
  animation: fadeIn 1s;
}
.serialInputDisabled {
  margin: auto;
  background: white;
  border: 1px rgba(0, 0, 0, 0.2) solid;
  border-radius: 5px;
  animation: fadeIn 1s;
}
.globalSelectionAAD {
  width: 80%;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  transition: 1s;
}
.mainButtonsAAD {
  display: flex;
  width: 300px;
  height: 40px;
  align-items: flex-start;
  justify-content: space-evenly;
  z-index: 1;
}
.mainButtonsAAD > button {
  background: #4ab217;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  width: 80px;
  height: 30px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: -2px -0 5px rgba(0, 0, 0, 0.8);
  transition: 0.3s;
}
.mainButtonsAAD > button:hover {
  color: black;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.8);
}
.mainButtonsAAD > .disabledANS {
  box-shadow: none;
  background: rgba(0, 0, 0, 0.2);
  cursor: default;
}
.mainButtonsAAD > .disabledANS:hover {
  color: white;
  cursor: not-allowed;
  box-shadow: none;
}
.mainButtonsAAD > .cancellButton {
  background: #f9323f;
}
.mainButtonsAAD > .cancellButton:hover {
  color: black;
}
.savedNoteAAD {
  margin: 0 auto;
  color: #4ab217;
  animation: fadeIn 1s;
  font-weight: bold;
}
.dasLineAAD {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 350px;
  height: 30px;
}
.dasLineAAD > * {
  margin: 5px;
}
.dasDescAAD {
  width: 30%;
  text-align: right;
  text-shadow: -2px -0 5px rgba(0, 0, 0, 0.2);
}
.dasValueAAD {
  width: 60%;
  text-align: left;
  box-shadow: -2px -0 10px rgba(0, 0, 0, 0.2);
  background: white;
  border: 1px black solid;
  border-radius: 5px;
  animation: fadeIn 1s;
}
.addDevicesTable {
  border: 1px black solid;
  border-radius: 10px;
  width: 100%;
}
.addDevicesTableWrapper {
  width: 100%;
  max-width: 882px;
  margin: auto;
  overflow-x: auto;
  border-radius: 10px;
}
.MuiTableBody-root:last-child tr td {
  padding-left: 5px !important;
}
.removeButtonAAD {
  margin: auto;
  margin-left: auto;
  background: #f9323f;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 5px;
  transition: 0.3s;
}
.removeButtonAAD:hover {
  color: black;
}
