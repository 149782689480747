.serviceUsageSelectors,
.serviceUsageScopeSelector,
.serviceUsagePeriodSelector {
  display: flex;
}
.serviceUsageSelectors {
  width: 100%;
}
.serviceUsageScopeSelector,
.serviceUsagePeriodSelector {
  margin: 36px 5px;
  min-width: 150px;
}
.serviceUsage {
  width: 90%;
  /* height: 500px; */
  margin: 0 auto;
}
.serviceUsageSelectorDescription {
  margin: 0 3px;
}
.sliderContainer {
  margin: 5px 20px;
  width: 100%;
  margin: 40px 15px;
}
.recharts-label {
  display: flex;
}
.serviceUsageButton {
  background: #4ab217;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  height: 30px;
  width: 120px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: -2px 0 5px rgb(0 0 0 / 80%);
  margin-bottom: 10px;
  transition: font-size 200ms;
}

.scopeDisplay {
  align-self: right;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
}
.scopeDisplay > span {
  margin: 0 10px;
}
.serviceDetailsUsageTable {
  margin: 15px 0;
  width: 100%;
  height: 250px;
  border-radius: 10px;
}
.loadingPar {
  cursor: wait;
}
.sdbInactive {
  background: #4ab217;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  height: 30px;
  width: 120px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: -2px 0 5px rgb(0 0 0 / 80%);
  margin-bottom: 10px;
  margin-left: 3px;
  margin-right: 3px;
  box-shadow: none;
  background: rgba(0, 0, 0, 0.2);
  cursor: default;
}
.serviceUsageButton,
.sdbInactive {
  margin-left: 1vh;
  margin-right: 1vh;
}
.sdbInactive:hover {
  color: white;
  cursor: not-allowed;
  box-shadow: none;
}
