.SimcardMoveBulk {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 2s;
  animation: fadeIn 1s;
}
.iccInput,
.idInput {
  margin: auto;
  box-shadow: -2px -2px 5px 2px rgba(0, 0, 0, 0.2);
  background: white;
  border: 1px black solid;
  border-radius: 5px;
  animation: fadeIn 1s;
}
.dateAndStatus {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 300px;
  justify-content: space-evenly;
  align-items: center;
  background: rgba(53, 100, 126, 0.1);
  box-shadow: -2px -0 5px rgba(0, 0, 0, 0.2);
  padding: 20px 50px;
  border: 1px rgba(0, 0, 0, 0.15) solid;
  border-radius: 5px;
  z-index: 5;
  position: relative;
}
.mainButtonsAUS {
  display: flex;
  width: 300px;
  height: 40px;
  align-items: flex-start;
  justify-content: space-evenly;
  z-index: 1;
}
.mainButtonsAUS > button {
  background: #4ab217;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  width: 80px;
  height: 30px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: -2px -0 5px rgba(0, 0, 0, 0.8);
}
.mainButtonsAUS > button:hover {
  color: black;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.8);
}
.mainButtonsAUS > .disabled {
  box-shadow: none;
  background: rgba(0, 0, 0, 0.2);
  cursor: default;
}
.mainButtonsAUS > .disabled:hover {
  color: white;
  cursor: not-allowed;
  box-shadow: none;
}
.mainButtonsAUS > .cancellButton {
  background: #f9323f;
}
.mainButtonsAUS > .cancellButton:hover {
  color: black;
}
.scanFields {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-wrap: wrap;
}
.statusGroup > * {
  margin: 0;
  text-shadow: -2px -0 5px rgba(0, 0, 0, 0.2);
}
.statusGroup {
  margin: 5px;
  display: flex;
  text-shadow: -2px -0 5px rgba(0, 0, 0, 0.2);
}
.scanLine {
  display: flex;
  box-shadow: -2px -0 5px rgba(0, 0, 0, 0.2);
  margin-top: 2px;
  border: 1px rgba(0, 0, 0, 0.15) solid;
  border-radius: 5px;
  animation: fadeIn 0.5s;
}
.scanLineTag {
  margin: 10px;
  text-shadow: -2px -0 5px rgba(0, 0, 0, 0.2);
  flex-wrap: wrap;
}
.scanLineTag div {
  width: 150px;
  margin: 0;
}
.scanLineStatus {
  text-align: left;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 10px;
}
.removeButton {
  margin: auto;
  margin-left: auto;
  background: #f9323f;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  width: 80px;
  height: 30px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: -2px -0 5px rgba(0, 0, 0, 0.4);
  margin-right: 5px;
  transition: 0.3s;
}
.removeButton:hover {
  box-shadow: 0px 0px 50px 0px rgba(247, 50, 63, 1);
  color: black;
}
.scanLineTag > input {
  width: 88px;
  box-shadow: -2px -0 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.mainButtons {
  display: flex;
  width: 300px;
  height: 50px;
  align-items: flex-end;
  justify-content: space-evenly;
  z-index: 1;
}
.mainButtons > button {
  background: #4ab217;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  width: 80px;
  height: 30px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: -2px -0 5px rgba(0, 0, 0, 0.8);
}
.mainButtons > button:hover {
  box-shadow: 0px 0px 50px 0px #4ab217;
  color: black;
}
.mainButtons > .disabled {
  box-shadow: none;
  background: rgba(0, 0, 0, 0.2);
  cursor: default;
}
.mainButtons > .disabled:hover {
  color: white;
  cursor: not-allowed;
  box-shadow: none;
}
.mainButtons > .cancellButton {
  background: #f9323f;
}
.mainButtons > .cancellButton:hover {
  box-shadow: 0px 0px 50px 0px rgba(247, 50, 63, 1);
  color: black;
}
.ll {
  margin-top: 20vh;
}
.savedNote {
  margin-top: 20px;
  color: #4ab217;
  animation: fadeIn 1s;
}
.checkDes {
  width: 105px;
}
.checkVal {
  align-self: flex-start;
  width: 20px;
  margin-left: 10px;
  margin-right: 200px;
}
.checkLine {
  justify-content: center;
  width: 350px;
}
.globalSelectionGroupAUS {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background: rgba(53, 100, 126, 0.1);
  box-shadow: -2px -0 5px rgba(0, 0, 0, 0.2);
  padding: 5px;
  border: 1px rgba(0, 0, 0, 0.15) solid;
  border-radius: 5px;
  position: relative;
  margin: 10px;
  width: 350px;
  transition: 1s;
}
.globalSelectionAUS {
  width: 80%;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  transition: 1s;
}
.savedNoteAUS {
  margin: 0 auto;
  height: 25px;
  color: #4ab217;
  animation: fadeIn 1s;
  font-weight: bold;
}
.updateSimsTable {
  border: 1px black solid;
  border-radius: 10px;
  width: 100%;
}
.updateSimsTableWraper {
  width: 100%;
  max-width: 850px;
  margin: auto;
  overflow-x: auto;
  border-radius: 10px;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
