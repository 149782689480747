.SimcardAddNew {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.scanLineTag {
  display: flex;
  align-items: center;
  justify-content: center;
}
.scanLineTag > div {
  display: flex;
  flex-direction: column;
  margin: 10px;
}
.msisdnInput,
.iccInput,
.pinInput,
.pukInput {
  margin: auto;
  box-shadow: -2px -0 10px rgba(0, 0, 0, 0.2);
  background: white;
  border: 1px black solid;
  border-radius: 5px;
  animation: fadeIn 1s;
}
.msisdnInputDisabled,
.pinInputDisabled,
.pukInputDisabled {
  margin: auto;
  background: white;
  border: 1px rgba(0, 0, 0, 0.2) solid;
  border-radius: 5px;
  animation: fadeIn 1s;
}
.globalSelectionGroupAAS {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background: rgba(53, 100, 126, 0.1);
  box-shadow: -2px -0 5px rgba(0, 0, 0, 0.2);
  padding: 5px;
  border: 1px rgba(0, 0, 0, 0.15) solid;
  border-radius: 5px;
  position: relative;
  margin: 10px;
  width: 330px;
  transition: 1s;
}
.globalSelectionAAS {
  width: 80%;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  transition: 1s;
}
.mainButtonsAAS {
  display: flex;
  width: 300px;
  height: 40px;
  align-items: flex-start;
  justify-content: space-evenly;
  z-index: 1;
}
.mainButtonsAAS > button {
  background: #4ab217;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  width: 80px;
  height: 30px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: -2px -0 5px rgba(0, 0, 0, 0.8);
}
.mainButtonsAAS > button:hover {
  color: black;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.8);
}
.mainButtonsAAS > .disabled {
  box-shadow: none;
  background: rgba(0, 0, 0, 0.2);
  cursor: default;
}
.mainButtonsAAS > .disabled:hover {
  color: white;
  cursor: not-allowed;
  box-shadow: none;
}
.mainButtonsAAS > .cancellButton {
  background: #f9323f;
}
.mainButtonsAAS > .cancellButton:hover {
  color: black;
}
.savedNoteAAS {
  margin: 0 auto;
  height: 25px;
  color: #4ab217;
  animation: fadeIn 1s;
  font-weight: bold;
}
.addSimsTable {
  border: 1px black solid;
  border-radius: 10px;
  width: 100%;
}
.addSimsTableWrapper {
  width: 100%;
  max-width: 850px;
  margin: auto;
  overflow-x: auto;
  border-radius: 10px;
}
