.mainButtonsAAD {
  display: flex;
  /* width: 300px; */
  /* height: 40px; */
  display: flex;
  /* justify-content: space-evenly; */
  align-items: center;
  justify-content: center;
  z-index: 1;
  margin: auto;
}
.mainButtonsAAD > button {
  background: #4ab217;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  width: 80px;
  height: 30px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: -2px -0 5px rgba(0, 0, 0, 0.8);
  margin: 5px;
}
.mainButtonsAAD > button:hover {
  color: black;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.8);
}
.mainButtonsAAD > .disabledANS {
  box-shadow: none;
  background: rgba(0, 0, 0, 0.2);
  cursor: default;
}
.mainButtonsAAD > .disabledANS:hover {
  color: white;
  cursor: not-allowed;
  box-shadow: none;
}
.mainButtonsAAD > .cancellButton {
  background: #f9323f;
}
.mainButtonsAAD > .cancellButton:hover {
  color: black;
}
.routers {
  display: flex;
  flex-direction: column;
}
.routers > * {
  margin: 0;
}
.routersFilter {
  border-radius: 5px;

  width: 80px;
  margin: 5px;
  height: 24px;
}
.antennaTagInput {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 200px;
}
.tagInputField {
  width: 70px;
  height: 16px !important;
  padding: 0;
  margin: 0 0 0 1px !important;
}
.cameraInput {
  margin: auto;
  display: flex;
  justify-content: center;
}
.antennaSigCont {
  width: 100%;
  max-width: 500px;
  height: 1000px;
  /* margin: auto; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.normalTableRow,
.inactiveAntennaBand,
.activeAntennaBand,
.activeDelivery {
  /* background-color: #f9323f !important; */
  /* color: white !important; */
  padding: 0 !important;
}
.activeAntennaBand > td {
  /* font-weight: 800; */
  /* font-style: italic; */
}
.activeAntennaBand > * {
  color: white !important;
}
.activeDelivery {
  background-color: #f9323f !important;
  color: white !important;
  padding: 0 !important;
}
.inactiveDelivery {
  /* background-color: #f9323f !important; */
  /* color: white !important; */
  padding: 0 !important;
}
.activeDelivery > * {
  color: white !important;
}
.tableContainer {
  margin: 11px 0 0 0;
  width: 100%;
  box-shadow: -5px -5px 10px 5px rgba(0, 0, 0, 0.2);
}
.gaugeContainer {
  margin-top: 6px;
  width: 110%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* box-shadow: -5px -5px 10px 5px rgba(0, 0, 0, 0.2); */
}
.gaugeContainer > div {
  width: 100% !important;
}
.barsContainer {
  margin: 160px 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* box-shadow: -5px -5px 10px 5px rgba(0, 0, 0, 0.2); */
}
.barsContainer > * {
  margin-bottom: 20px;
  box-shadow: -5px -5px 10px 5px rgba(0, 0, 0, 0.2);
}
/* .gaugeContainer {
  margin
} */
.inputArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto 0 auto;
  width: 90%;
}
.modemInfoArea {
  height: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 20px auto 0 auto;
  width: 90%;
}
.modemInfoArea2 {
  height: 80px;
}
.infoLine {
  display: flex;
  height: 20px;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.infoLineKey {
  height: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: 600;
  width: 50%;
}
.infoLineValue {
  height: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.infoLineKey > * {
  margin: 2px;
  height: 20px;
}
.infoLineValue > * {
  margin: 2px;
  height: 20px;
}
.infoLine > * {
  margin: 2px;
  height: 20px;
}
.antennaSigSpeed {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.antennaSigSpeed > * {
  justify-content: start;
  /* width: 00px; */
  /* text-align: left; */
}
.speedResults {
  margin-top: 5px;
  display: flex;
  text-align: left;
}
.speedResults > * {
  display: flex;
  width: 120px;
  justify-content: center;
}
.loadingIcon {
  margin-left: 10px;
  animation: fadeIn 3s, rotate 2s infinite;
}

.beeper {
  display: flex;
  margin-top: -230px;
  width: 100%;
  justify-content: flex-end;
  /* margin: -240px -370px 0 0; */
  /* width: 20px; */
  /* height: 60px; */
  /* background-color: #35647e; */
  /* border-radius: 20px; */
}
@keyframes pulse {
  0% {
    filter: invert(40%) sepia(87%) saturate(3757%) hue-rotate(334deg)
      brightness(97%) contrast(115%);
    transform: scale(1.2);
    /* opacity: 0.8; */
  }
  30% {
    transform: scale(1);
  }
  100% {
    filter: invert(40%) sepia(87%) saturate(0%) hue-rotate(334deg)
      brightness(0%) contrast(115%);
    transform: scale(1);
  }
}
@keyframes pulse2 {
  0% {
    filter: invert(40%) sepia(87%) saturate(3757%) hue-rotate(334deg)
      brightness(97%) contrast(115%);
    transform: scale(1.2);
    /* opacity: 1; */
  }
  30% {
    transform: scale(1);
  }
  100% {
    filter: invert(40%) sepia(87%) saturate(0%) hue-rotate(334deg)
      brightness(0%) contrast(115%);
    transform: scale(1);
  }
}
.blueFilter {
  /* filter: invert(34%) sepia(33%) saturate(668%) hue-rotate(157deg)
    brightness(94%) contrast(91%); */
  filter: invert(40%) sepia(87%) saturate(0%) hue-rotate(334deg) brightness(0%)
    contrast(115%);
  animation: pulse 3s;
}
.redFilter {
  filter: invert(40%) sepia(87%) saturate(0%) hue-rotate(334deg) brightness(0%)
    contrast(115%);
  /* filter: invert(40%) sepia(87%) saturate(3757%) hue-rotate(334deg)
    brightness(97%) contrast(115%); */
  animation: pulse2 3s;
}
