.ServiceDetailsWrapper,
.newInvoicesWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 0.9;
  justify-content: flex-start;
}
.serDetails {
  width: 90%;
}
.ServiceDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 2s;
  animation: fadeIn 1s;
}
.details {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  flex: 1 1 0;
  text-shadow: -2px -0 5px rgba(0, 0, 0, 0.2);
}
.tab {
  flex: 1 1 0;
  min-width: 450px;
  max-width: 600px;
  max-height: 300px;
  margin: 30px;
  box-shadow: -5px -5px 10px 5px rgba(0, 0, 0, 0.2);
  border: 1px solid black;
}
.hwDetails1,
.hwDetails2,
.hwDetails3 {
  background: rgba(53, 100, 126, 0.1);
  border-radius: 5px;
}
.history,
.MuiTableHead-root {
  border-radius: 5px;
}
.hwDetails1Line {
  display: flex;
}
.key,
.value {
  height: auto;
  margin: 5px;
  line-height: 25px;
}
.key {
  width: 30%;
  text-align: right;
}
.value,
.check {
  display: flex;
  width: 60%;
  text-align: left;
}
.check {
  width: 30px;
}
.check > input {
  appearance: auto;
  height: 20px;
  align-self: center;
  display: block;
}
.invDetailsButtons {
  display: flex;
  width: 300px;
  height: 50px;
  align-items: flex-end;
  justify-content: space-evenly;
}
.invDetailsButtons > .edit,
.save,
.cancel,
.info {
  background: #4ab217;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  width: 80px;
  height: 30px;
  cursor: pointer;
  border-radius: 5px;
  transition: 0.3s;
  box-shadow: -2px -0 5px rgba(0, 0, 0, 0.8);
}
.invDetailsButtons > .edit:hover,
.save:hover {
  box-shadow: 0px 0px 50px 0px #4ab217;
  color: black;
}
.invDetailsButtons > .info:hover {
  color: black;
}
.invDetailsButtons > .cancel {
  background: #f9323f;
}
.invDetailsButtons > .cancel:hover {
  box-shadow: 0px 0px 50px 0px rgba(247, 50, 63, 1);
  color: black;
}
.invDetailsButtons > .disabled {
  box-shadow: none;
  background: rgba(0, 0, 0, 0.2);
  cursor: default;
}
.invDetailsButtons > .disabled:hover {
  color: white;
  cursor: not-allowed;
  box-shadow: none;
}
.info {
  background: none;
  border: none;
  color: #4ab217;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  width: 80px;
  height: 30px;
  cursor: default;
  border-radius: 5px;
  box-shadow: none;
}
.filterInput {
  width: 80%;
}
input {
  width: 80%;
  height: 20px;
}
.smallDateInput {
  width: 40px;
}
.ll {
  margin-top: 20vh;
}
.value > input,
select {
  text-align: left;
  box-shadow: -2px -0 10px rgba(0, 0, 0, 0.2);
  background: white;
  border: 1px black solid;
  /* border-radius: 5px; */
  animation: fadeIn 1s;
}
.top {
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: fadeIn 1s;
}
.doubleSimWarning {
  color: #f9323f;
  text-align: center;
  text-decoration: none;
  padding: 5px;
  margin: 15px 0 0 0;
  height: 30px;
  border-radius: 5px;
  transition: 0.3s;
  animation: fadeIn 1s;
}
