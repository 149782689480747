/* .DataUsage {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 0.9;
  justify-content: flex-start;
} */
.poolsDataUsage {
  border-radius: 10px 0px 0px 10px;
}
.alertRed {
  background: #f9323f;
  color: white !important;
}
